<template>
  <div>
    <section class="main services">
      <div class="hero parallax"></div>

      <h2>{{ currentRouteName }}</h2>

      <article>
        <div class="thumb"></div>
        <p class="intro">
          GammaTeam Security provides quality and customized <router-link to="/services">security services</router-link> for commercial properties. Our team of security professionals recognizes that staff, clients and visitors’ safety is vitally important for success of your business operations. Therefore, we are 100% proactive and alert in order to guarantee a desired level of defense that will retain your commercial success.<br /><br />

          Our security specialists will review building plans and vehicle traffic patterns in internal building parking areas, evaluate needs for any perimeter barriers, develop notification protocols for emergency situations. With GammaTeam Security, you can be sure your building is being smoothly operated, and you are always aware of the situation in place.<br /><br />

          Each building has a specific level of security as well as each situation dictates certain protective measures to be taken. Our Executive Team will provide you with:
        </p>
        <ul>
          <li v-for="(item, i) in measures" :key="i">{{ item }}</li>
        </ul>
      </article>

      <call-to-action :link_back=true></call-to-action>
    </section>
  </div>
</template>

<script>
export default {
  name: 'CommercialSecurity',
  data: () => ({
    measures: ['Thorough security consultation to identify security needs for your property', 'Comprehensive security plan', 'Security recommendations', 'Professional uniformed armed and unarmed security guards', 'Exceptional security services', 'Our 24/7 support when hired']
  })
}
</script>

<style scoped>
section.main article div.thumb {
  background-image: url("/images/services/commercial.jpg");
}
</style>
